body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img {
  width: 100%;
}
.Header {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.9rem;
  background: #fff !important;
}
.Header
  a.d-flex.align-items-center.mb-2.mb-lg-0.text-white.text-decoration-none {
  margin-right: 25px;
}

.Header .btn-warning {
  color: #fff;
  background-color: #d50f50;
  border-color: #ffffff;
  border-width: 2px;
  border-radius: 21px;
  font-weight: 900;
  padding: 10px 15px;
}

.Footer {
  background: #d50f50;
}

.Footer h5 {
  color: #fff;
}

.Footer .btn-warning {
  color: #fff;
  background-color: #d50f50;
  border-color: #d50f50;
}
.Map .Map-bordes_top_bottom {
  border-top: 3px solid #d50f50;
  border-bottom: 3px solid #d50f50;
}
.Map .row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.Map .icon-container {
  margin: 30px 0;
}

.Map .icon {
  float: left;
  margin-right: 10px;
}

.Map .icon-text {
  display: block;
}

.Map .icon-text .phone {
  display: block;
  margin: 6px 0;
}

.Map .icon-text .email {
  display: block;
  margin: 4px 0;
}

.Map .icon .fa-2x {
  font-size: 2em;
  color: #d50f50;
}

.Contact{
  margin-bottom:60px;
}
.Contact .Contact_image {
  width: 60%;
  max-width: 80%;
  margin-left: 20%;
}

.Contact h1 {
  color: #d50f50;
  text-align: center;
  font-size: 3rem;
  font-weight: 900;
  margin-top:5rem;
}

.Contact h1.phone {
  color: #d50f50;
  text-align: center;
  font-size: 4rem;
  font-weight: 900;
  margin-top: 3rem;
}

.Contact h2 {
  color: #d50f50;
  font-weight: 600;
  margin-top: 15px;
}
.Contact a {
  text-decoration: none;
}

.Contact .Contact-text {
   width: 80%;
   margin: 0 auto;
   text-align: justify;
   margin-top: 10px;
}

.Contact .fill {
  background: #efeded;
  padding: 20px 0;
  border-top: 2px solid #d50f50;
  border-bottom: 2px solid #d50f50;
  margin: 30px 0px;
}
.Banner {
  background: url(../img/bannerlarge.png);
  background-size: cover;
  height: 280px;
  margin: 20px 0px;
}

.Banner .col-md-6.cont {
  background:none;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Banner .cont .text {
  color: #fff;
  font-size: 2.2rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
}
.Banner .cont .text a {
  text-decoration: none;
  color: #fff;
}
.Banner .cont .text h1 {
  color: #fff;
  font-weight: 900;
  font-size: 3.5rem; 
}

.Banner .cont img {
  width: 35%;
  margin: 0 30%;
}

.Secction {
  background: url(../img/secction.png);
  background-size: cover;
  height: 300px;
  margin: 20px 0px;
}

.Services .container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.Services .container h2 {
  font-weight: 600;
  color: #d50f50;
  font-size: 3.5rem;
}

.Services .container .text {
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  margin-bottom: 40px;
}

.Services .container img {
  width: 25%;
  margin: 19px 50%;
}

.Services .pulse:hover {
  animation: pulse 1s infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
    100% {
      transform: scale(1);
    }
  }
}
.MainSlider {
  overflow: hidden;
  position: relative;
  background: #000;  
}

.MainSlider:before{
  content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.8;
    background-image: url('../img/baner.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
} 
.MainSlider .slick-slide img {
  display: block;
  height: auto;
  z-index: 2;

}


.MainSlider .FormContact {
  padding: 30px  15px;
  margin: 50px 20px;
  background: #fff;
  border-radius: 25px;
}

.MainSlider .container {
}

.MainSlider .col-xs-12,
.MainSlider  .col-sm-12,
.MainSlider  .col-md-6,
.MainSlider  .col-lg-6{
  z-index: 10;
}

.MainSlider .FormContact .form-group {
  margin: 12px 0px;
}

.MainSlider .FormContact .btn-primary {
  width: 100%;
  background-color: #d50f50;
  border-color: #d50f50;
}

.MainSlider .FormContact .btn-primary:hover {
  background-color: #d50f50;
  border-color: #d50f50;
}

.MainSlider .FormContact h1 {
  color: #d50f50;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5em;
}

.MainSlider .FormContact label {
  font-size: 0.88rem;
  font-weight: 900;
}
.Aboust{
  margin: 5rem 0;
}
.Aboust .container h1 {
  font-weight: 600;
  color: #d50f50;
  font-size: 3.5rem;
}

.Aboust .Aboust-text {
  font-size: 1.3rem;
  font-weight: 300;
  margin-top: 20px;
}
.Aboust img {
  max-width: 35%;
}
/*Móviles en horizontal
   ------------------------------------------------------------------------- */
@media (min-width: 320px) {
  .MainSlider .slick-slide img {
    height: auto;
  }
  .Services .container h2 {
    font-size: 2.5rem;
  }
  .Services .container .text {
    font-size: 1rem;
  }
  .Services .container img {
    width: 25%;
    margin: 20px 30%;
  }
  .Banner .col-md-6.cont {
    background: #0000006b;
    margin-top: 25px;
  }
  .Banner .cont .text h1 {
    color: #fff;
    font-weight: 900;
    font-size: 2.5rem;
  }
  .Map .icon {
    float: left;
    margin-right: 10px;
    margin-left: 8px;
  }
}

/*Móviles en horizontal
   ------------------------------------------------------------------------- */
@media (min-width: 480px) {
  .MainSlider .slick-slide img {
    height: auto;
  }
  .Services .container h2 {
    font-size: 2.5rem;
  }
  .Services .container .text {
    font-size: 1rem;
  }
  .Services .container img {
    width: 25%;
    margin: 20px 30%;
  }
  .Banner .col-md-6.cont {
    background: #0000006b;
    margin-top: 25px;
  }
  .Banner .cont .text h1 {
    color: #fff;
    font-weight: 900;
    font-size: 2.5rem;
  }
  .Map .icon {
    float: left;
    margin-right: 10px;
    margin-left: 8px;
  }
}

/* Móviles en horizontal o tablets en vertical
   ------------------------------------------------------------------------- */
@media (min-width: 768px) {
  .MainSlider .slick-slide img {
    height: auto;
  }
  .Services .container h2 {
    font-size: 3.5rem;
  }
  .Services .container .text {
    font-size: 1.5rem;
  }
  .Services .container img {
    width: 25%;
    margin: 20px 30%;
  }
  .Banner .col-md-6.cont {
    background:none;
    margin-top: 25px;
  }
  .Banner .cont .text h1 {
    color: #fff;
    font-weight: 900;
    font-size: 2.5rem;
  }
  .Map .icon {
    float: left;
    margin-right: 10px;
    margin-left: 9px;
  }
  .Map .icon-text .email {
    display: block;
    margin: 4 px 0;
    overflow: hidden;
}
}

/* Tablets en horizonal y escritorios normales
   ------------------------------------------------------------------------- */
@media (min-width: 1024px) {
 
  .MainSlider .slick-slide img {
    height: auto;
  }
  .Services .container h2 {
    font-size: 3.5rem;
  }
  .Services .container .text {
    font-size: 1.5rem;
  }
  .Services .container img {
    width: 25%;
    margin: 20px 30%;
  }
  .Banner .col-md-6.cont {
    background:none;
    margin-top: 25px;
  }
  .Banner .cont .text h1 {
    color: #fff;
    font-weight: 900;
    font-size: 2.5rem;
  }
  .Map .icon {
    float: left;
    margin-right: 10px;
    margin-left: 9px;
  }
  .Map .icon-text .email {
    display: block;
    margin: 4 px 0;
    overflow: hidden;
}
}

/* Escritorios muy anchos
   ------------------------------------------------------------------------- */
@media (min-width: 1200px) {
  .MainSlider .slick-slide img {
    height: auto;
  }
  
  .Services .container h2 {
    font-size: 3.5rem;
  }
  .Services .container .text {
    font-size: 1.5rem;
  }
  .Services .container img {
    width: 25%;
    margin: 20px 30%;
  }
  .Banner .col-md-6.cont {
    background:none;
    margin-top: 25px;
  }
  .Banner .cont .text h1 {
    color: #fff;
    font-weight: 900;
    font-size: 3.5rem;
  }
  .Map .icon {
    float: left;
    margin-right: 10px;
    margin-left: 9px;
  }
  .Map .icon-text .email {
    display: block;
    margin: 4 px 0;
    overflow: hidden;
}
}
